<template>
<div>
    <v-row>
        <v-col>
            <v-card class="rounded-lg" flat min-height="400">
                <v-card flat :disabled="skeleton">
                    <v-tabs centered v-model="vista" icons-and-text class="pt-1">
                        <v-tab class="rounded-lg">
                            Turnos
                            <v-icon>mdi-format-list-checkbox</v-icon>
                        </v-tab>
                        <v-tab class="rounded-lg">
                            Personal
                            <v-icon>mdi-account-group-outline</v-icon>
                        </v-tab>
                        <v-tab class="rounded-lg">
                            Info
                            <v-icon>mdi-help-circle-outline</v-icon>
                        </v-tab>
                    </v-tabs>
                </v-card>
                <v-tabs-items v-model="vista">
                    <!--Turnos-->
                    <v-tab-item transition="fade" reverse-transition="fade">
                        <v-card flat >
                            <v-container v-if="skeleton">
                                <v-row>
                                    <v-col cols="12">
                                        <v-skeleton-loader type="list-item-two-line" width="100%"></v-skeleton-loader>
                                    </v-col>
                                    <v-col cols="6" md="3" sm="3" v-for="x in 4" :key="x">
                                        <v-skeleton-loader type="image" v-for="n in 1" :key="n" height="150"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container v-else>
                                <v-row>
                                    <v-col cols="10" offset="1" md="6" offset-md="3" lg="4" offset-lg="4" class="px-0">
                                        <v-autocomplete outlined dense v-model="buscar.turno" :items="ListaTurnos" item-text="nombre" item-value="id" label="Buscar Turno" hide-details clearable :menu-props="{offsetY: true, rounded:'lg', transition:'slide-y-transition', closeOnContentClick:true}" append-icon="">
                                            <template v-slot:item="data">
                                                <v-list-item link @click="GetTurno(data.item.id)">
                                                    <v-list-item-avatar class="my-1 mr-1">
                                                        <v-avatar size="30" :color="TemaDark?'grey darken-2':'secondary'">
                                                            <v-icon size="14" :color="TemaDark?'grey lighten-4 ':'primary'">mdi-text-box-multiple-outline</v-icon>
                                                        </v-avatar>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <span class="text-capitalize">{{data.item.nombre}}</span>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>{{data.item.tipo}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle v-if="data.item.obs" class="text-capitalize">
                                                            <v-icon size="13" class="mr-0" style="margin-bottom:2px;">mdi-information-outline</v-icon>
                                                            {{data.item.obs}}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <template v-slot:append-outer>
                                                <v-menu transition="scale-transition" rounded="lg" v-if="datos.turnos.edit" origin="right top" left>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon color="primary" dark v-bind="attrs" v-on="on" class="my-n2">
                                                            <v-icon size="22">mdi-cog-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list dense>
                                                        <v-list-item @click="OpenEdit">
                                                            <v-list-item-avatar class="mr-4" size="35" color="secondary">
                                                                <v-icon size="20" color="primary">mdi-playlist-plus</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title> Agregar Turno</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item @click="OpenNulo">
                                                            <v-list-item-avatar class="mr-4" size="35" color="secondary">
                                                                <v-icon size="20" color="primary">mdi-playlist-remove</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title> Ver turnos nulos</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="3" md="3" v-for="(tipos, index) in ListaGrupoTurnos" :key="index">
                                        <v-card ripple outlined class="text-center pa-2 d-flex align-center rounded-lg" height="150" @click="AbrirListaTurnos(index)">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-avatar :color="TemaDark?'primary':'secondary'" size="50">
                                                            <v-icon size="25" :color="TemaDark?'grey lighten-4 ':'primary'">
                                                                mdi-text-box-multiple-outline
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" class="pt-0 px-0">
                                                        <p class="trenos-title-card  mb-0" :class="TemaDark?'grey--text text--lighten-2':'grey--text text--darken-2'">{{NombreTarjeta(index)}}</p>
                                                        <v-chip class="ma-0 rounded-lg" :color="TemaDark?'grey darken-3 ':'secondary'" v-if="CheckNuevos(index)" style="height: 24px">
                                                            <v-list-item-subtitle class="font-weight-medium mb-0" style="line-height: 20px;">
                                                                <span class="text-capitalize "  :class="TemaDark?'grey--text text--lighten-1':'primary--text'">{{CheckNuevos(index)}}</span>
                                                                <v-icon size="15" :color="TemaDark?'grey lighten-1':'primary'" class="ml-1" style="margin-bottom: 2px !important;">mdi-information-outline</v-icon>
                                                            </v-list-item-subtitle>
                                                        </v-chip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                    <!--Usuarios-->
                    <v-tab-item transition="fade" reverse-transition="fade">
                        <v-card flat >
                            <v-container v-if="skeleton">
                                <v-row>
                                    <v-col cols="12">
                                        <v-skeleton-loader type="list-item-two-line" width="100%"></v-skeleton-loader>
                                    </v-col>
                                    <v-col cols="6" md="3" sm="3" v-for="x in 4" :key="x">
                                        <v-skeleton-loader type="image" v-for="n in 1" :key="n" height="150"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container v-else>
                                <v-row>
                                    <v-col cols="10" offset="1" md="6" offset-md="3" lg="4" offset-lg="4" class="px-0">
                                        <v-autocomplete outlined dense v-model="buscar.personal" :items="ListaPersonal" :filter="FiltroPersonal" item-value="id" label="Buscar Personal" hide-details clearable :menu-props="{offsetY: true, rounded:'lg', transition:'slide-y-transition', closeOnContentClick:true}" append-icon="">
                                            <template v-slot:item="data">
                                                <template>
                                                    <v-list-item link @click="GetUsuario(data.item.id)">
                                                        <v-list-item-avatar class="my-1 mr-1">
                                                            <v-avatar size="30" :color="TemaDark?'grey darken-2':'secondary'">
                                                                <v-icon size="14" :color="TemaDark?'grey lighten-4 ':'primary'">mdi-account-circle-outline</v-icon>
                                                            </v-avatar>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="text-capitalize">{{data.item.nombre}} {{data.item.apellido}}</v-list-item-title>
                                                            <v-list-item-subtitle>{{data.item.tipo}}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" md="4" offset-md="2">
                                        <v-card ripple outlined class="text-center pa-2 d-flex align-center rounded-lg" height="150" @click="AbrirListaPersonal('full')">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-avatar :color="TemaDark?'primary':'secondary'" size="50">
                                                            <v-icon size="25" :color="TemaDark?'grey lighten-4 ':'primary'">
                                                                mdi-account-group-outline
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" class="pt-0 px-0">
                                                        <p class="trenos-title-card mb-0" :class="TemaDark?'grey--text text--lighten-2':'grey--text text--darken-2'">Full Time</p>
                                                        <!--<v-chip class="ma-0 rounded-lg" color="secondary" text-color="primary" v-if="CantidadPersonal('full')" style="height: 24px">
                                                            <v-list-item-subtitle class="font-weight-medium mb-0" style="line-height: 20px;">
                                                                <v-icon size="15" color="primary" class="ml-1" style="margin-bottom: 2px !important;">mdi-information-outline</v-icon>
                                                                <span class="text-capitalize primary--text"> {{CantidadPersonal('full')}}</span>
                                                            </v-list-item-subtitle>
                                                        </v-chip>-->
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6" md="4" >
                                        <v-card ripple outlined class="text-center pa-2 d-flex align-center rounded-lg" height="150" @click="AbrirListaPersonal('part')">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-avatar :color="TemaDark?'primary':'secondary'" size="50">
                                                            <v-icon size="25" :color="TemaDark?'grey lighten-4 ':'primary'">
                                                                mdi-account-group-outline
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" class="pt-0  px-0">
                                                        <p class="trenos-title-card mb-0" :class="TemaDark?'grey--text text--lighten-2':'grey--text text--darken-2'">Part Time</p>
                                                        <!--<v-chip class="ma-0 rounded-lg" color="secondary" text-color="primary" v-if="CantidadPersonal('part')" style="height: 24px">
                                                            <v-list-item-subtitle class="font-weight-medium mb-0" style="line-height: 20px;">
                                                                <v-icon size="15" color="primary" class="ml-1" style="margin-bottom: 2px !important;">mdi-information-outline</v-icon>
                                                                <span class="text-capitalize primary--text"> {{CantidadPersonal('part')}}</span>
                                                            </v-list-item-subtitle>
                                                        </v-chip>-->
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                    <!--Info-->
                    <v-tab-item transition="fade" reverse-transition="fade">
                        <v-card flat >
                            <v-container v-if="skeleton">
                                <v-row>
                                    <v-col cols="12">
                                        <v-skeleton-loader type="list-item-two-line" width="100%"></v-skeleton-loader>
                                    </v-col>
                                    <v-col cols="6" md="3" sm="3" v-for="x in 4" :key="x">
                                        <v-skeleton-loader type="image" v-for="n in 1" :key="n" height="150"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container v-else>
                                <v-row>
                                    <v-col cols="6" md="4" offset-md="2">
                                        <v-card ripple outlined class="text-center pa-2 d-flex align-center rounded-lg" height="150" @click="OpenDialog('contacto_lista')">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-avatar :color="TemaDark?'primary':'secondary'" size="50">
                                                            <v-icon size="25" :color="TemaDark?'grey lighten-4':'primary'">
                                                                mdi-phone-log-outline
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" class="pt-0 px-0">
                                                        <p class="trenos-title-card mb-0" :class="TemaDark?'grey--text text--lighten-2':'grey--text text--darken-2'">Contactos</p>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6" md="4" >
                                        <v-card ripple outlined class="text-center pa-2 d-flex align-center rounded-lg" height="150" disabled>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-avatar :color="TemaDark?'primary':'secondary'" size="50">
                                                            <v-icon size="25" :color="TemaDark?'grey lighten-4':'primary'">
                                                                mdi-map-marker-alert
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" class="pt-0 px-0">
                                                        <p class="trenos-title-card mb-0" :class="TemaDark?'grey--text text--lighten-2':'grey--text text--darken-2'">Prevenciones Via</p>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-col>
    </v-row>
    <TurnoLista :lista="turno_lista_datos" :turno_id_dialog="turno_id" :edit="datos.turnos.edit" v-if="vista == 0"/>
    <PersonalLista :lista="personal_lista_datos" :usuario_id_dialog="usuario_id" :edit="datos.usuarios.edit" v-if="vista == 1"/>
    <v-dialog v-model="dialog.turno_edit" persistent max-width="800" :transition="DialogAnimation" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black" v-if="!dialog.turno">
        <TurnoEdit :turno_datos="null" />
    </v-dialog>
    <TurnoNuloLista/>
    <ContactoLista :lista="datos.info.contactos" />
</div>
</template>

<script>
import {
    mapState,
    mapMutations
} from "vuex";
export default {
    components: {
        TurnoLista: () => import("@/components/TurnoLista"),
        PersonalLista: () => import("@/components/PersonalLista"),
        TurnoEdit: () => import("@/components/TurnoEdit"),
        TurnoNuloLista: () => import("@/components/TurnoNuloLista"),
        ContactoLista: () => import("@/components/ContactoLista"),
    },
    name: "Buscar",
    data() {
        return {
            vista: null,
            buscar: {
                turno: null,
                personal: null,
            },
            datos: {
                turnos: {},
                usuarios: {},
                info: {}
            },
            turno_id: null,
            turno_calendario: null,
            turno_lista_datos: {},
            personal_lista_datos: {},
            usuario_id: null,

            panel_usuario: [],
            skeleton: true,
        }
    },
    computed: {
        ...mapState(['dialog', 'usuario', 'update','sistema']),
        FiltroMaquinistas() {
            let personal = JSON.parse(JSON.stringify(this.datos.usuarios.lista));
            if (this.buscar.personal) {
                personal = personal.filter((maq) => {
                    return (
                        maq.nombre.includes(this.buscar.personal.toLowerCase()) ||
                        maq.apellido.includes(this.buscar.personal.toLowerCase())
                    );
                });
            }
            return personal;
        },
        ListaTurnos() {
            let lista = JSON.parse(JSON.stringify(this.datos.turnos.lista)),
                lista_buscar = []
            if (lista) {
                for (let tipo = 0; tipo < lista.length; tipo++) {
                    for (let grupo = 0; grupo < lista[tipo].turnos.length; grupo++) {
                        for (let turnos = 0; turnos < lista[tipo].turnos[grupo].lista.length; turnos++) {
                            let detalle = {
                                nombre: `Turno ${lista[tipo].turnos[grupo].lista[turnos].turno}`,
                                id: lista[tipo].turnos[grupo].lista[turnos].id,
                                tipo: lista[tipo].turnos[grupo].nombre,
                                obs: lista[tipo].turnos[grupo].lista[turnos].obs
                            }
                            if(lista[tipo].turnos[grupo].lista[turnos].nombre) detalle.nombre = lista[tipo].turnos[grupo].lista[turnos].nombre
                            lista_buscar.push(detalle)
                        }
                    }
                }
            }
            return lista_buscar
        },
        ListaGrupoTurnos(){
            let turnos_grupos
            if(this.datos.turnos.lista){
                turnos_grupos = JSON.parse(JSON.stringify(this.datos.turnos.lista))
                turnos_grupos.pop()   
            } 
            return turnos_grupos
        },
        ListaPersonal() {
            let lista = JSON.parse(JSON.stringify(this.datos.usuarios.lista))
            return lista
        },
        UpdateComponente() {
            return this.update.buscar
        },
        DialogEdit() {
            return this.dialog.turno_edit
        },
        DialogTurno(){
            return this.dialog.turno
        },
        DialogUsuario(){
            return this.dialog.usuario
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods: {
        ...mapMutations(["ShowSnackBar", "ErrorRequest", 'OpenDialog', 'CloseDialog']),
        async GetData() {
            await this.axios.get(`/buscar`, {
                    headers: {
                        token: this.usuario.token,
                    },
                })
                .then((respuesta) => {
                    if (respuesta.data.mensaje) {
                        this.ErrorRequest(respuesta.data.mensaje)
                    } else {
                        this.skeleton = false
                        this.overlay = false
                        this.datos = respuesta.data
                        if (this.turno_lista_datos.index) {
                            this.turno_lista_datos = {
                                nombre: this.NombreTarjeta(this.turno_lista_datos.index),
                                datos: this.datos.turnos.lista[this.turno_lista_datos.index].turnos,
                                index: this.turno_lista_datos.index
                            }
                        }
                    }
                })
                .catch((err) => {
                    this.overlay = false
                    this.ErrorRequest(err)
                });
        },

        GetTurno(turno_id) {
            this.turno_id = turno_id
        },
        GetUsuario(usuario_id) {
            this.usuario_id = usuario_id
        },
        NombreTarjeta(numero) {
            let nombre
            switch (numero) {
                case 0:
                    nombre = 'Full Time'
                    break
                case 1:
                    nombre = 'Part Time'
                    break
                case 2:
                    nombre = 'Sábado'
                    break
                case 3:
                    nombre = 'Domingo'
                    break
            }
            return nombre
        },
        AbrirListaTurnos(index) {
            this.turno_lista_datos = {
                nombre: this.NombreTarjeta(index),
                datos: this.datos.turnos.lista[index].turnos,
                index
            }
            this.OpenDialog('turno_lista')
        },
        AbrirListaPersonal(tipo) {
            if (tipo == 'full') {
                this.personal_lista_datos = {
                    nombre: 'Full-Time',
                    datos: this.datos.usuarios.lista.filter(usuario => usuario.tipo.includes('Full')),
                    tipo: 'full'
                }
            }
            if (tipo == 'part') {
                this.personal_lista_datos = {
                    nombre: 'Part-Time',
                    datos: this.datos.usuarios.lista.filter(usuario => usuario.tipo.includes('Part')),
                    tipo: 'part'
                }
            }
            this.OpenDialog('personal_lista')
        },
        OpenEdit() {
            this.OpenDialog('turno_edit')
        },
        OpenNulo() {
            this.OpenDialog('turno_nulo')
        },
        CheckNuevos(index) {
            let nuevos = 0,
                turnos_nuevos = this.datos.turnos.lista[index].turnos.map((tipo) => { 
                    if(tipo.nuevo) return tipo.nuevo.length
                    else return 0
                })
            turnos_nuevos.forEach(function (numero) { nuevos += numero})
            if (nuevos > 1) nuevos = `${nuevos} nuevos`
            if (nuevos == 1) nuevos = `${nuevos} nuevo`
            return nuevos
        },
        CantidadPersonal(tipo) {
            let cantidad
            if (tipo == 'full') {
                cantidad = this.datos.usuarios.filter(usuario => usuario.tipo.includes('Full'));
            }
            if (tipo == 'part') {
                cantidad = this.datos.usuarios.filter(usuario => usuario.tipo.includes('Part'));
            }
            if (cantidad.length == 1) return '1 Persona'
            if (cantidad.length > 1) return `${cantidad.length} Personas`
            else return 0
        },
        FiltroPersonal(item, queryText) {
            return item.nombre.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.apellido.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.GetData()
    },
    watch: {
        UpdateComponente: function (val) {
            if (val) {
                //console.log('reiniciando componente buscar')
                this.GetData()
                this.update.buscar = false
            }
        },
        DialogEdit(val) {
            if (!val) {
                this.dialog_edit = false
                this.edit_datos = null
            }
        },
        DialogTurno(val){
            if(val) this.turno_id = null
        },
        DialogUsuario(val){
            if(val) this.usuario_id = null
        }
    }
};
</script>

<style scoped>
.v-list-item__avatar {
    align-self: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    margin-top: 5px;
}

.theme--dark.v-list{
    background-color: #424242 !important;
}
.v-input__append-outer {
    margin-top: 2px !important;
}
</style>
